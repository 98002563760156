import { lazy } from 'react';
import { RouteInterface } from 'src/types/routes.type';
import VitalsChart from 'src/modals/VitalsChart';
import PatientJourney from 'src/modals/PatientJourney';
import Product from 'src/modals/Inventory/product';
import AddProduct from 'src/modals/Inventory/product/AddProduct';
import ManageStaffGroup from 'src/modals/Manage StaffGroup';
import FamilyCard from 'src/modals/FamilyCard';
import MakePayment from 'src/pages/admin/Billing/MakePayment';
import BillingInvoicePreview from 'src/pages/admin/Billing/components/billing-invoice-preview';
import BulkUpload from 'src/modals/BulkUpload';
import Search from '../modals/Search';
import SwitchWorkspace from '../modals/SwitchWorkspace';
import AddPatient from '../modals/AddPatient';
import ViewAppointment from '../modals/ViewAppointment';
import InviteStaff from '../modals/InviteStaff';
import EditStaff from '../modals/EditStaff';
import AddAppointment from '../modals/AddAppointment';
import AddServices from '../modals/AddServices';
import EditService from '../modals/EditService';
import EditFacility from '../modals/EditFacility';
import AddFacility from '../modals/AddFacility';
import AddTodo from '../modals/AddTodo';
import TodoInfo from '../modals/TodoInfo';
import Notification from '../modals/Notification';
import Record from '../modals/Record';
import RecordTemplate from '../modals/RecordTemplate';
import Medication from '../modals/Medication';
import Allergies from '../modals/Allergies';
import BloodInfo from '../modals/BloodInfo';
import EditAppointment from '../modals/EditAppointment';
import NotesModal from '../modals/NotesModal';
import AddHMO from '../modals/addHMO';
import AddSettlement from '../modals/AddSettlement';
import SettlementInfo from '../modals/SettlementInfo';
import AddStep from '../modals/AddStep';
import ViewStep from '../modals/ViewStep';
import StepInfo from '../modals/StepInfo';
import PermissionsModal from '../modals/ViewPermissions';
import InventoryInvoice from '../modals/Inventory/invoice';

export const routes: RouteInterface[] = [
  {
    path: '/',
    component: lazy(() => import('../pages/Home')),
    isPrivate: true,
  },
  {
    path: '#search',
    component: Search,
    isModal: true,
  },
  {
    path: '#switch-workspace',
    component: SwitchWorkspace,
    isModal: true,
  },
  {
    path: '#family-card',
    component: FamilyCard,
    isModal: true,
    requiresId: true,
  },
  {
    path: '#add-patient',
    component: AddPatient,
    isModal: true,
    permission: 'ADD_NEW_PATIENT',
    showOnMobile: false,
  },
  {
    path: '#edit-patient',
    component: AddPatient,
    isModal: true,
    permission: 'EDIT_PATIENT',
    requiresId: true,
    showOnMobile: false,
  },
  {
    path: '#notes',
    component: NotesModal,
    isModal: true,
    requiresId: true,
    permission: 'ADD_NOTE_TO_PATIENT_PROFILE',
  },
  {
    path: '#view-appointment',
    component: ViewAppointment,
    isModal: true,
    requiresId: true,
    permission: 'VIEW_APPOINTMENT_DETAILS',
  },
  {
    path: '#invite-staff',
    component: InviteStaff,
    isModal: true,
    permission: 'INVITE_NEW_STAFF',
    showOnMobile: false,
  },
  {
    path: '#edit-staff',
    component: EditStaff,
    isModal: true,
    requiresId: true,
    permission: 'EDIT_STAFF',
    showOnMobile: false,
  },
  {
    path: '#add-appointment',
    component: AddAppointment,
    isModal: true,
    permission: 'CREATE_APPOINTMENT',
  },
  {
    path: '#edit-appointment',
    component: EditAppointment,
    isModal: true,
    requiresId: true,
    permission: 'EDIT_APPOINTMENT',
  },
  {
    path: '#add-services',
    component: AddServices,
    isModal: true,
    permission: 'ADD_NEW_SERVICE',
    showOnMobile: false,
  },
  {
    path: '#edit-service',
    component: EditService,
    isModal: true,
    requiresId: true,
    permission: 'EDIT_SERVICE',
    showOnMobile: false,
  },
  {
    path: '#owner-onboarding',
    component: lazy(() => import('src/modals/OwnerOnboarding')),
    isModal: true,
    showOnMobile: false,
    permission: 'UPDATE_ORGANISATION_INFORMATION',
  },
  {
    path: '#add-facility',
    component: AddFacility,
    isModal: true,
    permission: 'ADD_NEW_FACILITY',
    showOnMobile: false,
  },
  {
    path: '#edit-facility',
    component: EditFacility,
    isModal: true,
    requiresId: true,
    permission: 'EDIT_FACILITY',
    showOnMobile: false,
  },
  {
    path: '#create-todo',
    component: AddTodo,
    isModal: true,
    permission: 'CREATE_TODOS',
  },
  {
    path: '#create-task',
    component: AddTodo,
    isModal: true,
    requiresId: true,
    permission: 'CREATE_TODOS',
  },
  {
    path: '#todo-info',
    component: TodoInfo,
    isModal: true,
    requiresId: true,
    permission: 'VIEW_TODOS_DETAILS',
  },
  {
    path: '#make-payment',
    component: MakePayment,
    isModal: true,
    requiresId: true,
  },
  {
    path: '#update-todo',
    component: AddTodo,
    isModal: true,
    requiresId: true,
    permission: 'EDIT_TODOS',
    showOnMobile: false,
  },
  {
    path: '#notifications',
    component: Notification,
    isModal: true,
  },
  {
    path: '#record',
    component: Record,
    isModal: true,
    requiresId: true,
    permission: 'VIEW_PATIENT_RECORD',
  },
  {
    path: '#form',
    component: RecordTemplate,
    isModal: true,
    requiresId: true,
  },
  {
    path: '#medications',
    component: Medication,
    isModal: true,
    permission: 'ADD_MEDICATION_TO_PATIENT_RECORD',
  },
  {
    path: '#allergies',
    component: Allergies,
    isModal: true,
    permission: 'ADD_ALLERGIES_TO_PATIENT_RECORD',
  },
  {
    path: '#blood-info',
    component: BloodInfo,
    isModal: true,
    permission: 'EDIT_BLOOD_GROUP_INFO',
  },
  {
    path: '#add-hmo',
    component: AddHMO,
    isModal: true,
    permission: 'ADD_NEW_HMO',
  },
  {
    path: '#add-step',
    component: AddStep,
    isModal: true,
    permission: 'CREATE_A_NEW_STEP',
  },
  {
    path: '#step-info',
    component: StepInfo,
    isModal: true,
    requiresId: true,
  },
  {
    path: '#edit-step',
    component: AddStep,
    isModal: true,
    requiresId: true,
    permission: 'EDIT_STEP',
  },
  {
    path: '#add-settlement',
    component: AddSettlement,
    isModal: true,
    permission: 'CREATE_HMO_SETTLEMENTS',
  },
  {
    path: '#settlement-info',
    component: SettlementInfo,
    isModal: true,
    permission: 'VIEW_HMO_DETAILS',
    requiresId: true,
  },
  {
    path: '#edit-hmo',
    component: AddHMO,
    isModal: true,
    requiresId: true,
    permission: 'EDIT_HMO',
  },
  {
    path: '#vitals-chart',
    component: VitalsChart,
    isModal: true,
    permission: 'VIEW_VITALS_CHART',
  },
  {
    path: '#journey',
    component: PatientJourney,
    isModal: true,
    requiresId: true,
  },
  {
    path: '#billing-invoice',
    component: BillingInvoicePreview,
    isModal: true,
    requiresId: true,
  },
  {
    path: '#inventory-invoice',
    component: InventoryInvoice,
    isModal: true,
    requiresId: true,
  },
  {
    path: '#bulk-upload',
    component: BulkUpload,
    isModal: true,
    requiresId: true,
  },
  {
    path: '#view-step',
    component: ViewStep,
    isModal: true,
    requiresId: true,
    permission: 'VIEW_STEP_DETAILS',
  },
  {
    path: '#product',
    component: Product,
    isModal: true,
    requiresId: true,
    permission: 'VIEW_PRODUCT_DETAILS',
    showOnMobile: true,
  },
  {
    path: '#add-product',
    component: AddProduct,
    isModal: true,
    permission: 'ADD_TO_STOCK',
    showOnMobile: true,
  },
  {
    path: '#manage-staffgroup',
    component: ManageStaffGroup,
    isModal: true,
    hasModalParams: true,
    showOnMobile: false,
  },
  {
    path: '#view-permission',
    component: PermissionsModal,
    isModal: true,
    showOnMobile: true,
  },
  {
    path: '/auth',
    component: lazy(() => import('src/pages/auth/Auth')),
    subRoutes: [
      {
        path: '/signin',
        component: lazy(() => import('src/pages/auth/Signin')),
        isPrivate: false,
      },
      {
        path: '/reset-password',
        component: lazy(() => import('src/pages/auth/ResetPassword')),
        isPrivate: false,
      },
      {
        path: '/register-facility',
        component: lazy(() => import('src/pages/auth/facility/Registration')),
        isPrivate: false,
      },
      {
        path: '/facility-setup',
        component: lazy(() => import('src/pages/auth/facility/Setup')),
        isPrivate: false,
      },
      {
        path: '/forgot-password',
        component: lazy(() => import('src/pages/auth/ForgotPassword')),
        isPrivate: false,
      },
      {
        path: '/registration-waiting',
        component: lazy(
          () => import('src/pages/auth/facility/RegistrationWaiting'),
        ),
        isPrivate: false,
      },
    ],
  },
  {
    path: '/app/features',
    component: lazy(() => import('src/pages/features/')),
  },
  {
    path: '/admin/manage-staff',
    component: lazy(() => import('src/pages/admin/ManageStaff')),
    hasSidebar: true,
    isPrivate: true,
    permission: 'VIEW_STAFF_LIST',
    showOnMobile: false,
  },
  {
    path: '/admin/in-patients',
    component: lazy(() => import('src/pages/admin/InPatient')),
    hasSidebar: true,
    isPrivate: true,
    permission: 'VIEW_IN_PATIENTS_AND_THEIR_JOURNEY',
    showOnMobile: true,
  },
  {
    path: '/admin/manage-facility',
    component: lazy(() => import('src/pages/admin/ManageFacility')),
    hasSidebar: true,
    isPrivate: true,
    permission: 'VIEW_FACILITY_LIST',
    showOnMobile: false,
  },
  {
    path: '/admin/manage-step',
    component: lazy(() => import('src/pages/admin/ManageSteps')),
    hasSidebar: true,
    isPrivate: true,
    permission: 'VIEW_STEP_LIST',
    showOnMobile: false,
  },
  {
    path: '/admin/hmo',
    component: lazy(() => import('src/pages/admin/ManageHMO')),
    hasSidebar: true,
    isPrivate: true,
    permission: 'VIEW_HMO_LIST',
    showOnMobile: false,
  },
  {
    path: '/admin/hmo/:id',
    component: lazy(() => import('src/pages/admin/HMODetails')),
    hasSidebar: true,
    permission: 'VIEW_HMO_DETAILS',
    isPrivate: true,
  },
  {
    path: '/admin/services',
    component: lazy(() => import('src/pages/admin/ManageServices')),
    hasSidebar: true,
    isPrivate: true,
    permission: 'VIEW_SERVICE_LIST',
    showOnMobile: false,
  },
  {
    path: '/admin/inventory',
    component: lazy(() => import('src/pages/admin/ManageInventory')),
    hasSidebar: true,
    isPrivate: true,
    permission: 'VIEW_INVENTORY_LIST',
    showOnMobile: true,
  },
  {
    path: '/admin/patients',
    component: lazy(() => import('src/pages/admin/ManagePatients')),
    hasSidebar: true,
    isPrivate: true,
    permission: 'VIEW_PATIENT_LIST',
  },
  {
    path: '/admin/settings',
    component: lazy(() => import('src/pages/admin/ManageSettings')),
    hasSidebar: true,
    isPrivate: true,
    showOnMobile: true,
  },
  {
    path: '/admin/patients/:id',
    component: lazy(() => import('src/pages/admin/PatientDetails')),
    hasSidebar: true,
    isPrivate: true,
    permission: 'VIEW_PATIENT',
  },
  {
    path: '/admin/billing',
    component: lazy(() => import('src/pages/admin/Billing')),
    hasSidebar: true,
    isPrivate: true,
    permission: 'VIEW_PLAN_AND_BILLINGS'
  },
  {
    path: '/admin/billing/settings',
    component: lazy(() => import('src/pages/admin/Billing/paymentSettings')),
    hasSidebar: true,
    isPrivate: true,
    permission: 'VIEW_PLAN_AND_BILLINGS'
  },
  {
    path: '/admin/patients/:id/print',
    component: lazy(() => import('src/pages/admin/RecordPreview')),
    hasSidebar: true,
    isPrivate: true,
    permission: 'VIEW_PATIENT_RECORD',
    showOnMobile: false,
  },
  {
    path: '/admin/reports',
    component: lazy(() => import('src/pages/admin/ManageReports')),
    hasSidebar: true,
    isPrivate: true,
    permission: 'VIEW_REPORT',
  },
  {
    path: '/admin/reports/laboratory-report',
    component: lazy(
      () => import('src/pages/admin/ManageReports/Reports/laboratory-report'),
    ),
    isPrivate: true,
    hasSidebar: true,
    permission: 'VIEW_REPORT',
  },
  {
    path: '/admin/reports/visitation-report',
    component: lazy(
      () => import('src/pages/admin/ManageReports/Reports/visitation-report'),
    ),
    isPrivate: true,
    hasSidebar: true,
    permission: 'VIEW_REPORT',
  },
  {
    path: '/admin/reports/revenue-report',
    component: lazy(
      () => import('src/pages/admin/ManageReports/Reports/revenue-report'),
    ),
    isPrivate: true,
    hasSidebar: true,
    permission: 'VIEW_REPORT',
  },
  {
    path: '/admin/reports/hmo-report',
    component: lazy(
      () => import('src/pages/admin/ManageReports/Reports/hmo-report'),
    ),
    isPrivate: true,
    hasSidebar: true,
    permission: 'VIEW_REPORT',
  },
  {
    path: '/admin/overview',
    component: lazy(() => import('src/pages/admin/Overview')),
    hasSidebar: true,
    isPrivate: true,
    permission: ['VIEW_ADMINS_DASHBOARD', 'VIEW_DOCTORS_DASHBOARD'],
  },
  {
    path: '/calendar',
    component: lazy(() => import('src/pages/Calendar')),
    hasSidebar: true,
    isPrivate: true,
    permission: 'VIEW_CALENDAR',
  },
  {
    path: '/staff-invitation/new',
    component: lazy(() => import('src/pages/InvitedStaff')),
  },
  {
    path: '/staff-invitation/existing',
    component: lazy(() => import('src/pages/InvitedStaff/ExistingAccount')),
  },
  {
    path: '/renew-subscription',
    component: lazy(() => import('src/pages/RenewSubscription')),
  },
  {
    path: '/:rest*',
    component: lazy(() => import('src/pages/NotFound')),
  },
];
