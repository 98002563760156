import { ThreeDots } from 'src/assets/icons';
import { Row } from 'src/components';
import { DropdownList, DropdownListItem } from 'src/components/Dropdown/styled';
import { useDropdown } from 'src/hooks/useDropdown';
import { BillingInvoice } from 'src/types';
import { openModal } from 'src/utils/modalHelper';
import { TableActionButton } from './styled';


interface ActionColumnProps {
  row: BillingInvoice
}

export const BillingActionColumn = ({ row }: ActionColumnProps) => {

  const { id, status } = row

  const dropdowns = [
    {
      value: 'make-payment',
      label: 'Make Payment'
    },
    {
      value: 'make-payment',
      label: 'View Invoice',
    },
  ];

  const isPaidStatus = status?.toLowerCase() === 'paid';
  const dropdownItems = dropdowns.filter(el => {
    const isMakePayment = el.label.toLowerCase() === 'make payment';
    return isPaidStatus ? !isMakePayment : dropdowns;
  });



  const { toggleDropdown, isOpen, Dropdown } = useDropdown();


  // const generatePDF = () => {
  //   // Create new jsPDF instance
  //   const doc = new jsPDF();
    
  //   // Set font styles
  //   doc.setFont("helvetica");
    
  //   // Add invoice header
  //   doc.setFontSize(24);
  //   doc.text("INVOICE", 105, 20, { align: "center" });
  //   doc.setFontSize(12);
  //   doc.text(row?.invoiceId, 105, 30, { align: "center" });
    
  //   // Add invoice details
  //   doc.setFontSize(10);
    
  //   // Left column
  //   doc.text("Invoice To:", 20, 50);
  //   doc.text(organisation?.name, 20, 55);
    
  //   doc.text("Date Generated:", 20, 70);
  //   doc.text(moment(row?.createdAt).format('h:mma, MMM. D, YYYY'), 20, 75);
    
  //   doc.text("Paid By:", 20, 90);
  //   doc.text("undefined undefined", 20, 95);
    
  //   // Right column
  //   doc.text("Payment Method:", 120, 50);
  //   doc.text(row?.payment?.provider as string, 120, 55);
    
  //   doc.text("Payment Date:", 120, 70);
  //   doc.text(moment(row?.paidAt).format('h:mma, MMM. D, YYYY'), 120, 75);
    
  //   doc.text("Email Address:", 120, 90);
  //   doc.text(row?.staff?.user?.emails?.[0], 120, 95);
    
  //   // Add table headers
  //   doc.setFillColor(240, 240, 240);
  //   doc.rect(20, 110, 170, 10, "F");
  //   doc.text("Plan Name", 25, 116);
  //   doc.text("Billing Period", 85, 116);
  //   doc.text("Amount", 160, 116);
    
  //   // Add table content
  //   doc.text("Apr 07, 2025 - Jul 06, 2025", 85, 130);
  //   doc.text("₦10,000", 160, 130);
    
  //   // Add totals
  //   doc.text("Amount Due", 20, 150);
  //   doc.text("₦10,000", 160, 150);
    
  //   doc.text("Sub-Total", 20, 160);
  //   doc.text("₦10,000", 160, 160);
    
  //   // Add note
  //   doc.text("Note:", 20, 180);
  //   const note = "Invoice is an itemized commercial document that records the products or services delivered to facility mentioned on this document by Ankora Health Limited, the total amount due, and the preferred payment method.";
  //   doc.setFontSize(9);
  //   const splitNote = doc.splitTextToSize(note, 170);
  //   doc.text(splitNote, 20, 190);
    
  //   // Save PDF
  //   doc.save('invoice-MD0HUP.pdf');
  // };
  

  const handleDropdownChange = (value: string) => {
    // if (value === "download-invoice") {
    //   generatePDF()
    // }
    openModal(value, id);
    toggleDropdown()
  }


  return (
    <Row justify='center'>
      <TableActionButton
        type='button' 
        onClick={toggleDropdown}
      >
        <ThreeDots className="icon" />
      </TableActionButton>
      {isOpen && (
        <Dropdown>
          <DropdownList>
            {dropdownItems.map(({ value, label }, idx) => {
              return (
                <DropdownListItem
                  key={`${value}-${idx}`}
                  data-testid={label.toLowerCase()}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleDropdownChange(value);
                  }}
                >
                  {label}
                </DropdownListItem>
              );
            })}
          </DropdownList>
        </Dropdown>
      )}
    </Row>
  );
};