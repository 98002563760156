import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader, Row } from 'src/components';
import { Sidebar } from 'src/containers';
import NotFound from 'src/pages/NotFound';
import { Route, Switch, Redirect } from 'wouter';
import { RouteInterface, RouterProps } from 'src/types';
import { Logo, LogoDark } from 'src/assets/icons';
import { sidebar } from 'src/configs/sidebar';
import { useAuth, useStaff } from 'src/state';
import Validator from 'validatorjs';
import { checkPermission, removeModalHash } from 'src/utils';
import useMediaQuery from 'src/utils/useMediaQuery';
import _ from 'lodash';
import { NavigationDropdown } from 'src/components/NavigationDropdown';
import { ComponentWrapper, PaddingContainer, RouteWrapper } from './styled';

Validator.register(
  'at_least_one_item',
  (value) => (value as unknown as string[]).length > 0,
  'select at least one',
);

const useHashLocation = () => {
  const getHashLocation = useCallback(() => {
    return window.location.hash.replace(/^#/, '');
  }, []);

  const [hashLocation, setHashLocation] = useState(getHashLocation());

  useEffect(() => {
    const handler = () => {
      setHashLocation(getHashLocation());
    };
    window.addEventListener('hashchange', handler);
    return () => {
      window.removeEventListener('hashchange', handler);
    };
  }, [getHashLocation]);

  return [hashLocation];
};

const ModalManager: React.FC<RouterProps> = ({ routes }) => {
  const { state } = useAuth();
  const { staffPermissions } = useStaff();
  const modalRoutes = routes.filter(({ isModal }) => isModal);
  const [location] = useHashLocation();
  const [indexToRender, setIndexToRender] = useState(-1);
  const params = useMemo(() => new URLSearchParams(location), [location]);
  const isMobile = !useMediaQuery('(min-width: 960px)');

  useEffect(() => {
    if (modalRoutes[indexToRender]) {
      const { permission } = modalRoutes[indexToRender];

      if (
        permission &&
        staffPermissions &&
        !checkPermission(staffPermissions, permission) 
      ) {
        removeModalHash();
      }
    }
  }, [indexToRender, modalRoutes, staffPermissions]);

  useEffect(() => {
    const index = modalRoutes.findIndex(
      ({ path, requiresId, showOnMobile, hasModalParams }) => {
        const show = isMobile && showOnMobile === false;
        return (
          (!requiresId && !show && path?.replace('#', '') === location) ||
          ((requiresId || hasModalParams) &&
            !show &&
            location.includes('&') &&
            path?.replace('#', '') === location.split('&')[0])
        );
      },
    );
    setIndexToRender(index);
  }, [location, modalRoutes, params, isMobile]);

  if (modalRoutes[indexToRender] && state.token) {
    const Modal = modalRoutes[indexToRender].component;
    const { permission } = modalRoutes[indexToRender];
    return permission &&
      staffPermissions &&
      !checkPermission(staffPermissions, permission) ? null : (
        <Suspense fallback={<></>}>
          <Modal />
        </Suspense>
        
    );
  }
  return null;
};

export const RouteBuilder: React.FC<RouterProps> = ({ routes, base = '' }) => {
  const { state } = useAuth();
  const { staffPermissions } = useStaff();
  const isMobile = !useMediaQuery('(min-width: 960px)');

  // useEffect(() => {
  //   if (state.token && staff === null) {
  //     logOut();
  //   }
  // }, [staff, logOut, state.token]);

  // useEffect(() => {
  //   if (location === '/auth/signin' && state.token && staff !== null) {
  //     setLocation('/');
  //   }
  // }, [state.token, setLocation, location, staff]);
  

  const mobileRoutes: RouteInterface[] = _.filter(
    routes,
    (route) => route.showOnMobile !== false,
  );

  const appRoutes = isMobile ? mobileRoutes : routes;

  const parsedUrl = new URL(window.location.href);
  const redirectURL = (!state.token && parsedUrl.pathname === "/") ? "" : 
    `?redirect=${parsedUrl.pathname + parsedUrl.search + parsedUrl.hash}`;

  return (
    <RouteWrapper>
      <ModalManager routes={routes} />
      <Switch>
        <>
          {appRoutes.map(
            ({
              path,
              component: Component,
              subRoutes,
              hasSidebar,
              isPrivate,
              permission,
            }) => {
              if (isPrivate && !state.token) {
                return (
                  <Route
                    key={path}
                    path={subRoutes ? `${base}${path}/:rest*` : `${base}${path}`}
                  >
                    <Redirect to={`/auth/signin${redirectURL}`} />
                  </Route>
                );
              }
              return (
                <Route
                  key={path}
                  path={subRoutes ? `${base}${path}/:rest*` : `${base}${path}`}
                >
                  {!checkPermission(staffPermissions, permission) &&  <Redirect to="/" />}
                  <ComponentWrapper
                    hasSidebar={hasSidebar as boolean} 
                    gap={hasSidebar ? 2.5 : 0}
                  >
                    <Row
                      height="100vh"
                      width={
                        hasSidebar && !isMobile
                          ? `calc(100vw - ${
                              !path?.includes('print')
                                ? '11.625rem'
                                : '5.625rem'
                            })`
                          : '100%'
                      }
                    >
                      {isMobile && hasSidebar && (
                        <PaddingContainer justify="space-between" width="100%">
                          {isPrivate ? <LogoDark /> : <Logo />}
                          <NavigationDropdown />
                        </PaddingContainer>
                      )}
                      <Suspense fallback={<PageLoader />}>
                        <Component>
                          {subRoutes && (
                            <RouteBuilder
                              routes={subRoutes}
                              base={subRoutes ? `${base}${path}` : path}
                            />
                          )}
                        </Component>
                      </Suspense>
                    </Row>
                    {hasSidebar && !isMobile && <Sidebar config={sidebar} />}
                  </ComponentWrapper>
                </Route>
              );
            },
          )}
        </>
        <Route path={`${base}/:rest*`}>
          <NotFound />
        </Route>
      </Switch>
    </RouteWrapper>
  );
};
